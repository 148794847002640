<template>
    <div class="container">
        <header class="jumbotron">
            <h3>{{this.mode === 'edit' ? 'Edytuj' : 'Dodaj'}} jednoste</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12">
                <label for="name">Nazwa jednostki</label>
                <InputText id="name" type="text" v-model="content.name" placeholder="Wpisz nazwę jednostki"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="type">Typ</label>
                <Dropdown id="type" v-model="content.type" optionValue="id" :options="types" optionLabel="name"
                          placeholder="Wybierz typ"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="place">Miejscowość</label>
                <InputText id="place" type="text" v-model="content.place" placeholder="Wpisz miejscowość"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="zipCode">Kod pocztowy</label>
                <InputText id="zipCode" type="text" v-model="content.zipCode" placeholder="Wpisz kod pocztowy"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="street">Ulica</label>
                <InputText id="street" type="text" v-model="content.street" placeholder="Wpisz ulicę"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="buildingNumber">Numer budynku</label>
                <InputText id="buildingNumber" type="text" v-model="content.buildingNumber"
                           placeholder="Wpisz numer budynku"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="apartmentNumber">Numer mieszkania</label>
                <InputText id="apartmentNumber" type="text" v-model="content.apartmentNumber"
                           placeholder="Wpisz numer mieszkania"/>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"/>
            <Button type="button" label="Zapisz" v-on:click="click"/>
        </div>
    </div>
</template>

<script>
    import UnitService from "../../services/unit.service";

    export default {
        inject: ['global'],
        name: "AddEditUnit",
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: {
                    name: "",
                    place: "",
                    zipCode: "",
                    type: 0,
                    street: "",
                    buildingNumber: "",
                    apartmentNumber: ""
                }
            },
        },
        data() {
            return {
                types: '',
                content: this.initialCntent,
            };
        },
        methods: {
            click: function () {
                // this.content.type = this.content.type;
                if (this.mode === 'edit') {
                    UnitService.editUnit(this.content, this.$route.params.unitId).then(
                        (response) => {
                            console.log(response);
                            this.$router.push({path: `${this.global.state.instancePatch}/jednostki/lista`});
                        },
                        (error) => {
                            this.$toast.add({
                                severity: 'error',
                                summary: error.response.data.error,
                                detail: error.response.data.message,
                                life: 3000
                            });
                        }
                    );
                } else {
                    UnitService.addUnit(this.content).then(
                        (response) => {
                            console.log(response);
                            this.$router.push({path: `${this.global.state.instancePatch}/jednostki/lista`});
                        },
                        (error) => {
                            this.$toast.add({
                                severity: 'error',
                                summary: error.response.data.error,
                                detail: error.response.data.message,
                                life: 3000
                            });
                        }
                    );
                }
            },
            goBack: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/jednostki/lista`});
            }
        },
        mounted() {
            this.mode = this.$route.params.unitId ? 'edit' : 'add';
            console.log(this.mode);
            if (this.mode === 'edit') {
                UnitService.getUnit(this.$route.params.unitId).then(
                    (response) => {
                        this.content = response.data;
                        this.content.type = response.data.type.id;
                        this.content.id = undefined;
                        this.content.createdAt = undefined;
                        this.content.updatedAt = undefined;
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
            UnitService.getUnitsTypes().then(
                (response) => {
                    this.types = response.data;
                },
                (error) => {
                    this.types =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    };
</script>
